// @flow
import * as React from 'react';
import { useUserAgent } from 'next-useragent';
import classnames from 'classnames';

import type { HeaderComponent as HeaderComponentType } from '@/types/component';

import { UserAgentContext } from '@/globals/contexts';

import { COMPONENT_TYPE } from '@/PUFComponents/componentMap';
import PUFComponent from '@/PUFComponents';
import HeaderComplexComponent from '@/PUFComponents/base/navigation/HeaderComplexComponent';

const RETRACTED_HEADER_HEIGHT = 0;

type Options = any;
type Props = {|
  configuredComponent?: HeaderComponentType,
  options: Options,
  toggleMobileMenu: (boolean) => void,
  mobileMenuOpen: boolean,
|};

export default function Header({
  configuredComponent,
  options,
  toggleMobileMenu,
  mobileMenuOpen,
}: Props): React.Node {
  const [retracted, setRetracted] = React.useState(false);
  const normalHeaderRef = React.useRef(null);

  const useragent = React.useContext(UserAgentContext);
  const ua = useUserAgent(useragent);
  const mobileMode = ua.isMobile || ua.isTablet;

  React.useEffect(() => {
    const handleHeaderRetraction = (e) => {
      if (mobileMode) return;

      const windowScrollY = window.scrollY || window.pageYOffset;
      const el = normalHeaderRef.current;

      if (!el) return;

      const headerHeight = el.getBoundingClientRect().height;

      const shouldRetract =
        windowScrollY > headerHeight - RETRACTED_HEADER_HEIGHT;
      setRetracted(shouldRetract);
    };

    window.addEventListener('scroll', handleHeaderRetraction);

    return () => window.removeEventListener('scroll', handleHeaderRetraction);
  }, [mobileMode, setRetracted]);

  const HeaderComponent = ({
    retracted,
    toggleMobileMenu,
    mobileMenuOpen,
  }: {
    retracted: boolean,
    toggleMobileMenu?: (boolean) => void,
    mobileMenuOpen?: boolean,
  }) =>
    !!configuredComponent ? (
      <HeaderComplexComponent
        className="Header"
        component={configuredComponent}
        retracted={retracted}
        toggleMobileMenu={toggleMobileMenu}
        mobileMenuOpen={mobileMenuOpen}
      />
    ) : (
      <PUFComponent type={COMPONENT_TYPE.RAW} id="Header" options={options} />
    );

  return (
    <>
      <div
        ref={(node) => (normalHeaderRef.current = node)}
        className={classnames('header-wrapper', {
          'is-mobile-mode': mobileMode,
        })}
      >
        <HeaderComponent
          retracted={false}
          toggleMobileMenu={toggleMobileMenu}
          mobileMenuOpen={mobileMenuOpen}
        />
      </div>

      {retracted && <HeaderComponent retracted />}
    </>
  );
}
