// @flow
import * as React from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';

import type { User } from '@/types/models';
import type { SiteConfigOptions } from '@/types/site';

import config from '@/utils/config';
import { initGtm, gtmEvent } from '@/utils/googleTagManager';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { useSite } from '@/hooks';
import { getEnv } from '@/helpers/env';

type Props = {|
  user: null | User,
  options: SiteConfigOptions,
|};

export default function Tracker({ user, options }: Props): null {
  const router = useRouter();
  const site = useSite();

  const { gtmTrackingId } = options;
  const featureFlags = getFeatureFlags(site);
  const isGDPRFeatureFlagEnabled = featureEnabled('GDPRBanner', featureFlags);
  const { GIT_TAG } = config;
  const pufenv = getEnv();

  React.useEffect(() => {
    // Warning: googleAdsTrackingId and googleAdsTrackingId probably incompatible
    if (gtmTrackingId) {
      initGtm(gtmTrackingId, user);
    }

    gtmEvent({
      GDPREnabled: isGDPRFeatureFlagEnabled,
      frontEnvironment: pufenv.toUpperCase(),
      frontVersion: GIT_TAG,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    router.onRouteChangeComplete = (url) => {
      try {
        gtmEvent({ page: url });
      } catch (e) {
        Sentry.captureException(e);
      }
    };
  }, [router]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
