// @flow

import * as React from 'react';
import classnames from 'classnames';

import type { SiteContext, Page } from '@/types/site';
import type { Router } from '@/types/router';
import type { InstanceName } from '@/types/models';

import { featureEnabled, getFeatureFlags } from '@/helpers/models/instance';
import { CookiesContext, ImageContext } from '@/globals/contexts';
import { BY_PASS_MAINTENANCE_SECRET_COOKIE } from '@/constants/cookies';
import { isProd } from '@/helpers/env';

import AdminFooter from './AdminFooter';
import { LoginSubscriptionModal } from '@/PUFComponents/base/components';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner';
import MaintenancePage from './MaintenancePage';
import PopupManager from './PopupManager';

import '@/styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-tooltip/assets/bootstrap_white.css';

type Props = {|
  site: SiteContext,
  instanceName: InstanceName,
  page: Page,
  children: React.Node,
  router: Router,
|};

export default function AppLayout({
  children,
  page: pageProps,
  site: siteProps,
  instanceName,
  router,
}: Props): React.Node {
  const { cookies } = React.useContext(CookiesContext);
  const [mobileMenuOpen, toggleMobileMenu] = React.useState(false);
  const { site, siteConfig, headerComponent, footerComponent, popups } =
    siteProps;
  const customCSSKey = site && site.cssKey ? site.cssKey : '';
  const { noFooter, noHeader } = pageProps.page || {};
  const featureFlags = getFeatureFlags(siteProps.site);

  const className = classnames(
    'app',
    siteConfig.styles.template ? `template--${siteConfig.styles.template}` : '',
    `inst--${instanceName}`,
    {
      [`inst--${customCSSKey}`]: !!customCSSKey,
    }
  );

  React.useEffect(() => {
    const handleRouteChange = () => toggleMobileMenu(false);

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [toggleMobileMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldDisplayMaintenancePage =
    site.maintenanceMode &&
    (!site.bypassMaintenanceSecret ||
      cookies[BY_PASS_MAINTENANCE_SECRET_COOKIE] !==
        site.bypassMaintenanceSecret);

  if (shouldDisplayMaintenancePage) {
    return (
      <div className={className}>
        <MaintenancePage siteOptions={siteConfig.options} site={site} />
        <AdminFooter />
      </div>
    );
  }

  return (
    <ImageContext.Provider value={{ loadImagesSync: false }}>
      <div className={className}>
        {!noHeader && (
          <Header
            configuredComponent={headerComponent}
            options={siteConfig.header}
            toggleMobileMenu={toggleMobileMenu}
            mobileMenuOpen={mobileMenuOpen}
          />
        )}

        <div
          className={classnames('site', {
            'mobile-menu-open': mobileMenuOpen,
          })}
        >
          {children}
        </div>

        {!noFooter && (
          <Footer
            configuredComponent={footerComponent}
            options={siteConfig.footer}
          />
        )}

        {!isProd() && <AdminFooter />}

        <LoginSubscriptionModal />

        <div id="modal-root" />
        <div id="reader-root" />
        <PopupManager popups={popups} />

        {featureEnabled('GDPRBanner', featureFlags) && <CookieBanner />}
      </div>
    </ImageContext.Provider>
  );
}
