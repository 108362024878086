// @flow
import * as React from 'react';
import * as Nookies from 'nookies';
import { omit } from 'lodash';

import type { CookieOptions } from '@/types/cookies';

import { CookiesContext } from '@/globals/contexts';

type Props = {|
  cookies: { [cookieName: string]: string },
  children: React.Node,
|};

export default function CookieContextHandler({
  children,
  cookies: cookieProp,
}: Props): React.Node {
  const [cookies, setCookies] = React.useState(cookieProp);

  const setCookie = React.useCallback(
    (ctx: any, name: string, value: string, options: CookieOptions) => {
      Nookies.setCookie(ctx, name, value, options);
      setCookies((previousCookies) => ({
        ...previousCookies,
        [name]: value,
      }));
    },
    [setCookies]
  );

  const destroyCookie = React.useCallback(
    (cookieName: string) => {
      Nookies.destroyCookie(cookieName);
      setCookies((previousCookies) => omit(previousCookies, cookieName));
    },
    [setCookies]
  );

  return (
    <CookiesContext.Provider
      value={{
        cookies,
        setCookie,
        destroyCookie,
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
}
