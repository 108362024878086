import * as React from 'react';

import { Icon, Button } from '@/components/new';
import Layout from '@/Layout';

export default function HeaderV2MobileSideMenu({ closeMenu, layout }) {
  return (
    <div id="mobile-side-menu">
      <Button id="close-menu" onClick={closeMenu} isText>
        <Icon name="times" />{' '}
      </Button>

      <Layout layout={layout} isPageWrapper />
    </div>
  );
}
