import * as React from 'react';
import { useRouter } from 'next/router';

import { PAGES_WITH_NO_POPUP } from '@/utils/config';
import { Popup } from '@/PUFComponents/base/components';

export default function PopupManager({ popups }) {
  const router = useRouter();

  const currentPageId = router.query.pageId;

  const shouldShowPopup = React.useCallback(
    (popup) => {
      const { showOnPages, hideOnPages } = popup;

      if (Array.isArray(showOnPages) && showOnPages.length > 0) {
        // If the current page is among the ones the popup should be showed on
        // return true and ignore the rest
        return popup.showOnPages.some(
          (pageRef) => pageRef._id === currentPageId
        );
      }

      if (Array.isArray(hideOnPages) && hideOnPages.length > 0) {
        // Then, if the current page is NOT among the ones the popup should be hidden on
        // return true
        return !popup.hideOnPages.some(
          (pageRef) => pageRef._id === currentPageId
        );
      }

      return true;
    },
    [currentPageId]
  );

  if (!router || PAGES_WITH_NO_POPUP.includes(router.pathname)) return null;
  if (!Array.isArray(popups) || popups.length === 0) return null;

  return popups
    .filter(shouldShowPopup)
    .map((popup) => <Popup {...popup} key={popup._id} />);
}
