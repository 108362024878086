import _ from 'lodash';
import API2 from './API2';

/**
 * Non-static version for server (to fix global instance bug)
 */
class ApiClient2 {
  binds = {};
  watches = {};
  uid = 1;
  promisesCache = {};
  instanceName = null;

  static DONT_CACHE = {
    Video: true,
    cart: true,
    stock: true,
    //...
  };

  constructor(instanceName, draftMode, api) {
    this.instanceName = instanceName;
    this.draftMode = draftMode;

    this.api = api || new API2();
  }

  getEndPoint(resource, allowDraft = true) {
    if (resource === 'users' || resource === 'cart') return '/';
    if (resource === 'list')
      return this.draftMode ? '/list_draft/' : '/list_data/';
    return this.draftMode && allowDraft ? '/draft/' : '/data/';
  }

  _resetResourceCache(resource) {
    console.debug('ApiClient2> reset cache for all binds for', resource);
    this.promisesCache[resource] = {};
  }

  _resetCache(fullPath, params, resource) {
    let cacheKey = fullPath + '|' + JSON.stringify(params);
    _.unset(this.promisesCache, [resource, cacheKey]);
  }

  _get(fullPath, params, channel, resource) {
    if (this.instanceName === null) {
      console.error('ApiClient2> try to get data without instance', fullPath);
      return Promise.reject('no instance set');
    }

    if (
      typeof window === 'undefined' ||
      ApiClient2.DONT_CACHE[resource] ||
      ApiClient2.DONT_CACHE[resource.split('/').pop()]
    ) {
      return this.api.get(fullPath, params, channel);
    } else {
      let cacheKey = fullPath + '|' + JSON.stringify(params);
      // Beware of immutability !!!
      const cached = _.get(this.promisesCache, [resource, cacheKey]);
      if (cached) {
        return cached;
      } else {
        const promise = this.api.get(fullPath, params, channel);
        _.set(this.promisesCache, [resource, cacheKey], promise);
        promise.then(null, (err) => {
          console.debug('Error in request, removing from cache', fullPath, err);
          _.set(this.promisesCache, [resource, cacheKey], null);
        });
        return promise;
      }
    }
  }

  _post(fullPath, data, channel, resource) {
    if (this.instanceName === null) {
      console.error('ApiClient2> try to post data without instance', fullPath);
      return Promise.reject('no instance set');
    }

    this._resetResourceCache(resource);
    return this.api.post(fullPath, data, channel);
  }

  getObject(resource, id, params, callback, onError, channel = null) {
    if (!this.instanceName) {
      console.warn('No instance, no data', resource, id);
      onError('no instance');
      return;
    }

    let endPoint = this.getEndPoint(resource);
    let fullPath =
      this.instanceName + endPoint + resource + '/' + encodeURIComponent(id);
    this._get(fullPath, params, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  getCollection(resource, params, callback, onError, channel = null) {
    if (!this.instanceName) {
      console.warn('No instance, no data', resource);
      onError('no instance');
      return;
    }

    let endPoint = this.getEndPoint(resource);
    let fullPath = this.instanceName + endPoint + resource;
    this._get(fullPath, params, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  post(resource, id, data, callback, onError, channel = null) {
    let endPoint = this.getEndPoint(resource, false);
    let fullPath =
      this.instanceName +
      endPoint +
      resource +
      (id ? '/' + encodeURIComponent(id) : '');
    this._post(fullPath, data, channel, resource)
      .then(callback, onError)
      .catch((e) => this._catchError(e, fullPath, onError));
  }

  static _catchError(e, path, onError) {
    console.error('Exception in API callback', path, e);
    onError &&
      onError({
        code: -1,
        message: e && e.toString && e.toString(),
      });
  }

  static abort(channel) {
    this.api.abort(channel);
  }
}

export default ApiClient2;
