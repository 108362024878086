import React from 'react';
import cn from 'classnames';
import { useUserAgent } from 'next-useragent';

import { UserAgentContext } from '@/globals/contexts';

import PageLink from '../PageLinkV1';
import Layout from '@/Layout';

const RETRACTED_HEADER_CLASSNAME = 'header-retracted';
const EXPANDED_HEADER_CLASSNAME = 'header-not-retracted';
const HEADER_MOBILE_CLASSNAME = 'header-mobile';

const getClassNameIdentifiedStructure = (structure, className) =>
  structure.filter((row) => !!row.options?.className?.includes(className));

const getExpandedHeaderStructure = (structure) => {
  const classNameIdentifiedStructure = getClassNameIdentifiedStructure(
    structure,
    EXPANDED_HEADER_CLASSNAME
  );
  return classNameIdentifiedStructure.length > 0
    ? classNameIdentifiedStructure
    : structure.slice(0, -2); // tout sauf les 2 derniers
};

const getRetractedHeaderStructure = (structure) => {
  const classNameIdentifiedStructure = getClassNameIdentifiedStructure(
    structure,
    RETRACTED_HEADER_CLASSNAME
  );
  return classNameIdentifiedStructure.length > 0
    ? classNameIdentifiedStructure
    : structure.slice(-2, -1); // avant-denier
};

const getMobileHeaderStructure = (structure) => {
  const classNameIdentifiedStructure = getClassNameIdentifiedStructure(
    structure,
    HEADER_MOBILE_CLASSNAME
  );
  return classNameIdentifiedStructure.length > 0
    ? classNameIdentifiedStructure
    : structure.slice(-1); // dernier
};

export default function HeaderComplexComponent({
  component,
  retracted: headerRetracted,
  className,
}) {
  const useragent = React.useContext(UserAgentContext);
  const ua = useUserAgent(useragent);
  const mobileMode = ua.isMobile || ua.isTablet;
  const [mobileMenuToggled, setMobileMenuToggled] = React.useState(false);

  if (!component || !component.layout) {
    console.warn('component with null data', this, this.props);
    return (
      <div className="error draft-placeholder">
        Composant {component && (component.label || component._id)} vide
      </div>
    );
  }

  if (component._cls !== 'ComplexComponent')
    console.warn('weird component with layout', component);

  let componentClass = component._id || '';
  if (component && component.className) {
    componentClass = component.className;
  } else if (component && component.objectType && component.mode) {
    componentClass =
      component.objectType +
      component.mode.charAt(0).toUpperCase() +
      component.mode.slice(1);
  }

  const expandedHeaderStructure = getExpandedHeaderStructure(
    component.layout.structure
  );
  const retractedHeaderStructure = getRetractedHeaderStructure(
    component.layout.structure
  );
  const mobileHeaderStructure = getMobileHeaderStructure(
    component.layout.structure
  );

  const retracted = headerRetracted && !mobileMenuToggled;
  const structureToDisplay = mobileMode
    ? mobileHeaderStructure
    : retracted
    ? retractedHeaderStructure
    : expandedHeaderStructure;

  // TODO comment intégrer closeMobileMenu ?

  const layout = {
    ...component.layout,
    structure: structureToDisplay.map((row) => ({
      ...row,
      options: {
        ...(row.options || {}),
        className: `${row.options?.className} Header-row`,
      },
    })),
  };

  return (
    <>
      <PageLink pageId="home">
        <div id="mobile-logo" />
      </PageLink>

      <header
        role="banner"
        className={cn(componentClass, className, {
          'Header-not-retracted': !retracted && !mobileMode,
          'Header--retracted': retracted && !mobileMode,
          'Header--toggled': mobileMenuToggled,
          'Header--mobileMode': mobileMode,
        })}
        data-cc={component._id}
        onClick={() =>
          console.debug('click header') ||
          (mobileMenuToggled && setMobileMenuToggled(false))
        }
      >
        <Layout layout={layout} />

        {mobileMenuToggled && (
          <span
            className={cn('Header-toggle-menu', {
              toggled: mobileMenuToggled,
            })}
            onClick={() => setMobileMenuToggled(!mobileMenuToggled)}
          >
            <span>Menu</span>
          </span>
        )}
      </header>

      {!mobileMenuToggled && (
        <span
          className={cn('Header-toggle-menu', {
            toggled: mobileMenuToggled,
          })}
          onClick={() => setMobileMenuToggled(!mobileMenuToggled)}
        >
          <span>Menu</span>
        </span>
      )}
    </>
  );
}
