import * as React from 'react';

import { UserActions } from '@/reflux';
import { makeRef } from '@/helpers/models/schema';
import API from '@/utils/API';

import { PaywallContext } from '@/globals/contexts';
import { useGlobal } from '@/hooks';

import PaywallModal from './Modal';

const DEFAULT_PAYWALL_CONFIGURATION = {
  isActive: false,
};

export default function PaywallManager({ children }) {
  const [paywallModalState, setPaywallModalState] = React.useState(null);
  const { siteId, siteConfig, session } = useGlobal();
  const paywallConfiguration =
    siteConfig.paywallConfiguration || DEFAULT_PAYWALL_CONFIGURATION;

  const incrementView = React.useCallback(
    async (content) => {
      if (!paywallConfiguration.isActive) return;
      if (!content || !('authorized' in content)) return;
      if (!session) return;

      await API.post(`${siteId}/users/sessions/${session._id}/view`, {
        content: {
          ...makeRef(content),
          videoId: content.video?._id,
        },
      });

      const shouldDisplayRegistrationModal =
        session?.shouldDisplayRegistrationModal &&
        !content.authorized &&
        !!content.unauthorizedDetails?.solutions?.includes('register');

      if (shouldDisplayRegistrationModal) {
        setPaywallModalState({
          content,
          choice: 'register',
        });
        const updatedSession = await API.put(
          `${siteId}/users/sessions/${session._id}`,
          {
            hasSeenRegistrationModal: true,
          }
        );
        UserActions.setSession(updatedSession);
      }
    },
    [session, paywallConfiguration.isActive, siteId]
  );

  return (
    <PaywallContext.Provider
      value={{
        notifiyView: incrementView,
        openModal: setPaywallModalState,
      }}
    >
      {children(incrementView)}

      {paywallModalState && (
        <PaywallModal
          {...paywallModalState}
          onClose={() => setPaywallModalState(null)}
          onSuccess={(loginActionType) => {
            setPaywallModalState(null);
            paywallModalState.onSuccess &&
              paywallModalState.onSuccess(loginActionType);
          }}
          session={session}
        />
      )}
    </PaywallContext.Provider>
  );
}
