import React from 'react';

import { useSiteOptions, useGlobal } from '@/hooks';

import { Image, Icon, Button } from '@/components/new';
import PageLinkV2 from '../PageLinkV2';

export default function MobileHeader({ openMenu }) {
  const { mobileHeaderLogo } = useSiteOptions();
  const {
    links,
    user,
    siteConfig: {
      options: { siteTitle },
    },
  } = useGlobal();

  return (
    <div className="mobile-header">
      <div className="left-menu">
        <Button
          id="open-menu"
          onClick={openMenu}
          isText
          style={{ marginRight: 10 }}
        >
          <Icon name="bars" />
        </Button>

        <PageLinkV2 link={{ url: '/' }}>
          {(href) => (
            <a href={href} className="logo-link">
              <Image
                src={mobileHeaderLogo}
                width={120}
                height={20}
                alt={`Logo du ${siteTitle}`}
              />
            </a>
          )}
        </PageLinkV2>
      </div>

      <div className="right-menu">
        <PageLinkV2 link={{ url: links.subscribe.url }}>
          {(href) => (
            <Button style={{ marginRight: 5 }} href={href} size="tiny">
              Nos offres
            </Button>
          )}
        </PageLinkV2>

        <PageLinkV2
          link={{
            url: user ? links.myAccount.profile.url : links.login.login.url,
          }}
        >
          {(href) => (
            <Button isText href={href} size="tiny">
              <Icon name={user ? 'user' : 'user-alt-slash'} />
            </Button>
          )}
        </PageLinkV2>
      </div>
    </div>
  );
}
