import * as React from 'react';

import { makeRef } from '@/helpers/models/schema';

import NewLoginRegister from '@/PUFComponents/base/login/NewLoginRegister';
import { Modal } from '@/components/old';

// const TAB = {
//   LOGIN: 'login',
//   SUBSCRIBE: 'subscribe',
// };

// TODO: reorganize this modal code to nicely handle a signin/signup/subscription flow
function PaywallModal({
  content,
  onClose,
  choice: initialChoiceProp,
  onSuccess,
  session,
  options,
}) {
  const { unauthorizedDetails } = content;
  const { description, solutions, reason } = unauthorizedDetails;
  // const [tab, setTab] = React.useState(choice || TAB.LOGIN);

  const canRegister = solutions.includes('register');
  const canLogin = solutions.includes('login');
  const canSubscribe = solutions.includes('subscribe');
  const initialChoice = initialChoiceProp || (canLogin ? 'login' : 'register');
  const [choice, setChoice] = React.useState(initialChoice);
  // const canSubscribeOnly = canSubscribe && !canRegister && !canLogin;
  // const hasTab = canSubscribe && canLogin;

  let title = 'Contenu non accessible';

  switch (reason) {
    case 'limit_reached':
      title =
        choice === 'register'
          ? 'Inscrivez-vous gratuitement pour accéder à ce contenu'
          : 'Connectez-vous pour accéder à ce contenu';
      break;
    case 'unsufficient_rights':
      title = 'Contenu réservé aux abonnés';
      break;
    default:
      title = 'Contenu non accessible';
  }

  return (
    <Modal
      title={title}
      innerStyle={{ maxWidth: 550 }}
      onClose={onClose}
      additionalClassName="paywall-modal"
    >
      <p className="description">{description}</p>

      {(canLogin || canRegister) && ( // (!hasTab || tab === TAB.LOGIN) &&
        <NewLoginRegister
          initialChoice={initialChoice}
          canChoose={canLogin && canRegister}
          onSuccess={onSuccess}
          canSubscribe={canSubscribe}
          onChoose={setChoice}
          context={{ content: makeRef(content), session: makeRef(session) }}
          options={options}
        />
      )}
    </Modal>
  );
}

export default PaywallModal;
