// @flow
import _ from 'lodash';
import { RecoilRoot } from 'recoil';
import App from 'next/app';
import Router from 'next/router';
import nookies from 'nookies';
import * as React from 'react';
import Reflux from 'reflux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import getConfig from 'next/config';
import request from 'superagent';
import * as Sentry from '@sentry/browser';

import type { User, VisitorSession } from '@/types/models';
import type { Page, SiteContext as SiteContextType } from '@/types/site';
import type { NextAppContext } from '@/types/next';
import type { Router as RouterType } from '@/types/router';

import {
  SiteContext,
  PageContext,
  UserAgentContext,
  APIContext,
} from '@/globals/contexts';
import { InstanceStore, UserStore, CartStore } from '@/reflux';
import { trackAffiliateFromQuery } from '@/utils/affiliation';
import API2 from '@/utils/API2';
import ApiClient from '@/utils/ApiClient';
import ApiClient2 from '@/utils/ApiClient2';
import { setLocale } from '@/helpers/date';
import {
  ComponentMapContext,
  getInstanceComponentMap,
} from '@/PUFComponents/componentMap';
import { getFeatureFlags } from '@/helpers/models/instance';
import {
  retrieveInstanceInformation,
  getURLInformation,
} from '@/helpers/serverRendering';
import config from '@/utils/config';

import AppComponent from '@/pageComponents/AppComponent';

setLocale('fr');

type Props = {|
  pageProps: any,
  pageContext: Page,
  siteContext: SiteContextType,
  user: ?User,
  router: RouterType,
  Component: React.ComponentType<*>,
  cookies: { [cookieName: string]: string },
  session: VisitorSession,
  useragent: any,
|};

type State = {|
  siteContext: SiteContextType,
  fullUrl: string,
  useragent: any,
|};

export default class Next_App extends App {
  constructor(props: Props) {
    super(props);
    const { instanceId, draftMode } = props.siteContext;
    this.state = {
      siteContext: props.siteContext,
      fullUrl: props.pageProps?.fullUrl,
      useragent: props.useragent,
    };

    if (instanceId) {
      InstanceStore.INSTANCE_INITIAL_STATE = {
        instanceName: instanceId,
        draftMode,
      };
      ApiClient.switchInstance(instanceId, draftMode);
    }
  }

  // getServerSideProps is called once on page load on server side.
  // It fetches all information about the instance, as well as the specific page props
  static async getServerSideProps({
    Component,
    ctx: context,
    router,
  }: NextAppContext): any {
    const cookieHeaderStr = context.req.headers['cookie'];
    const URLInformation = getURLInformation(context.req, router.query);

    const api = new API2();
    api.setServerMode(cookieHeaderStr);

    const siteContext = await retrieveInstanceInformation(context, api);

    const { instanceId, draftMode } = siteContext;
    const featureFlags = getFeatureFlags(siteContext.site);
    const user = await api.get(`${instanceId}/users/session`, null);

    const cookies = nookies.get(context);
    let visitorId;
    if (!cookies.visitor) {
      visitorId = `${moment().unix()}-${uuidv4()}`;
      nookies.set(context, 'visitor', visitorId, {
        path: '/',
        maxAge: 7 * 24 * 3600, // One week
      });
    }

    const session = await api.get(`${instanceId}/users/sessions/initiate`, {
      sessionId: user?.sessionId || visitorId,
    });

    context.apiForServer = new ApiClient2(instanceId, draftMode, api);
    context.siteContext = siteContext;

    Reflux.initStore(InstanceStore);
    Reflux.initStore(UserStore);
    Reflux.initStore(CartStore);

    // TODO remove Reflux (deprecated, buggy and not SSR compatible.
    Reflux.setGlobalState({
      [InstanceStore.id]: { instanceId },
      [UserStore.id]: { user, session },
      [CartStore.id]: {
        instanceId,
        featureFlags,
        siteContext,
      },
    });

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(context)
      : {};

    return {
      pageProps,
      pageContext: {
        ...pageProps?.page,
        ...URLInformation,
      },
      siteContext,
      user,
      session,
      cookies: nookies.get(context),
      useragent: context.req.headers['user-agent'],
    };
  }

  // getClientSideProps is called on page change in the browser. It only request the new page props
  static async getClientSideProps({
    Component,
    ctx: context,
    router,
  }: NextAppContext): any {
    const URLInformation = {
      host: window.location.host,
      pathname: window.location.pathname,
      baseUrl: window.location.href.split('/').slice(0, 3).join('/'),
      fullUrl: window.location.href,
    };

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(context)
      : {};

    return {
      pageProps,
      pageContext: {
        ...pageProps?.page,
        ...URLInformation,
      },
    };
  }

  static async getInitialProps(appContext: NextAppContext): any {
    const { ctx: context } = appContext;

    trackAffiliateFromQuery(context);

    return context.req
      ? this.getServerSideProps(appContext)
      : this.getClientSideProps(appContext);
  }

  async componentDidMount() {
    const { pageProps } = this.props;
    window.currentUrl = Router.asPath;
    Router.events.on('routeChangeComplete', (url) => {
      if (_.startsWith(url, '/p/') || _.startsWith(url, '/o/')) {
        window.previousUrl = window.currentUrl;
        window.currentUrl = url;
        this.setState({ fullUrl: `${pageProps.baseUrl}${url}` });
      }
    });

    setInterval(async () => {
      const config = getConfig();
      try {
        const { obsolete: hasObsoleteVersion } = (
          await request
            .get('/frontApi/hasObsoleteVersion')
            .query({ frontBuildId: config.publicRuntimeConfig?.BUILD_ID })
        ).body;
        if (hasObsoleteVersion) {
          window.location.reload();
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }, 1000 * 60 * 10);
  }

  render(): React.Node {
    const {
      pageProps,
      router,
      user,
      Component,
      cookies,
      session,
      pageContext,
    } = (this.props: Props);
    const { siteContext, useragent } = (this.state: State);
    const { siteId } = siteContext;
    const { baseUrl } = pageContext;
    const featureFlags = getFeatureFlags(siteContext.site);
    this.componentMap =
      this.componentMap || getInstanceComponentMap(siteId, featureFlags);

    const apiContext = {
      getImageUrl: (path) =>
        `${
          config.STATIC_IMAGES_URL || baseUrl + '/api'
        }/${siteId}/images/show${path}`,
      siteId,
      userContributionUploadUrl: `${config.API_URL}/${siteId}/user-contributions/upload`,
      userContributionUrl: `${config.API_URL}/${siteId}/user-contributions`,
      userContributionPath: `${siteId}/user-contributions`,
    };

    return (
      <RecoilRoot>
        <SiteContext.Provider value={siteContext}>
          <PageContext.Provider value={pageContext}>
            <UserAgentContext.Provider value={useragent}>
              <ComponentMapContext.Provider value={this.componentMap}>
                <APIContext.Provider value={apiContext}>
                  <AppComponent
                    baseUrl={pageContext.baseUrl}
                    fullUrl={pageContext.fullUrl}
                    siteContext={siteContext}
                    pageProps={pageProps}
                    router={router}
                    user={user}
                    session={session}
                    Component={Component}
                    cookies={cookies}
                    apiContext={apiContext}
                  />
                </APIContext.Provider>
              </ComponentMapContext.Provider>
            </UserAgentContext.Provider>
          </PageContext.Provider>
        </SiteContext.Provider>
      </RecoilRoot>
    );
  }
}
