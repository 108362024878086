import * as React from 'react';

import {
  Field,
  Label,
  InputCheckboxBase,
  InputTextBase,
  InputSelectBase,
} from '@/components/old';
import { Icon, Control } from '@/components/new';

export default function UserFormField({
  fieldId,
  value,
  fieldConfig,
  onChange,
  options,
  hint,
  error,
}) {
  const {
    label,
    displayLabel,
    placeholder,
    options: selectOptions,
    type,
    name,
    autoComplete,
    required,
  } = fieldConfig;
  const className = `user-form-field-${fieldId}`;

  switch (fieldConfig.valueType) {
    case 'string':
    case 'date':
      let suffix;

      if (typeof options.revealPassword === 'boolean') {
        suffix = (
          <Icon
            name={options.revealPassword ? 'eye' : 'eye-slash'}
            onClick={options.toggleViewPassword}
            additionalClassName="is-right"
          />
        );
      }

      return (
        <Field additionalClassName={className}>
          {label && displayLabel && <Label>{label}</Label>}

          <Control hasIconsRight={!!suffix}>
            <InputTextBase
              name={name}
              value={value}
              placeholder={placeholder}
              type={options.type || type}
              required={required}
              onChange={onChange}
            />

            {suffix}
          </Control>

          {hint && <div className="form-hint">{hint}</div>}
          {error && <div className="form-error">{error}</div>}
        </Field>
      );
    case 'enum':
      return (
        <Field additionalClassName={className}>
          {label && displayLabel && <Label>{label}</Label>}
          <InputSelectBase
            useValue
            options={selectOptions}
            placeholder={placeholder}
            name={name}
            autoComplete={autoComplete}
            required={required}
            value={value}
            onChange={onChange}
          />
          {hint && <div className="form-hint">{hint}</div>}
          {error && <div className="form-error">{error}</div>}
        </Field>
      );
    case 'bool':
      return (
        <Field additionalClassName={className}>
          <InputCheckboxBase
            label={label}
            clickableLabel
            value={value}
            onChange={onChange}
          />
          {hint && <div className="form-hint">{hint}</div>}
          {error && <div className="form-error">{error}</div>}
        </Field>
      );
    default:
      return null;
  }
}
