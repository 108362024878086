import * as React from 'react';

import { useLinks } from '@/hooks';

import PageLink from '@/PUFComponents/base/navigation/PageLinkV1';

export default function ForgotPasswordLink({ text = 'Mot de passe oublié ?' }) {
  const {
    login: { forgotPass: forgotPassLink },
  } = useLinks();

  return (
    <PageLink link={forgotPassLink}>
      <a className="forgot-pass-link">
        <span>{text}</span>
      </a>
    </PageLink>
  );
}
