// @flow
import * as React from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

import { clearTrackingCookies, setConsentCookie } from '@/helpers/cookies';
import { consentEvent } from '@/utils/googleTagManager';
import { CONSENT_SET_COOKIE_NAME, CONSENT_COOKIES } from '@/constants/cookies';
import { getCookieDomain } from '@/helpers/url';
import { CookiesContext } from '@/globals/contexts';

export default function CookieBanner(): React.Node {
  const router = useRouter();
  const { cookies, setCookie } = React.useContext(CookiesContext);
  const consentSetCookie = parseInt(cookies[CONSENT_SET_COOKIE_NAME], 10);
  const [displayed, setDisplayed] = React.useState(consentSetCookie !== 1);
  const domain = getCookieDomain();

  const setCookies = React.useCallback(
    (cookieValue: 1 | 0, { consent }: { consent: boolean }) => {
      if (consent) {
        setConsentCookie(setCookie, CONSENT_SET_COOKIE_NAME, 1, domain);
      }

      setDisplayed(false);
      CONSENT_COOKIES.forEach((cookie) => {
        setConsentCookie(setCookie, cookie.name, cookieValue, domain);
      });

      consentEvent({
        performanceCookieValue: cookieValue,
        targetingCookieValue: cookieValue,
        socialCookieValue: cookieValue,
      });

      if (cookieValue === 0) {
        clearTrackingCookies({
          performanceCookieValue: cookieValue,
          targetingCookieValue: cookieValue,
          socialCookieValue: cookieValue,
        });
      }

      router.reload();
    },
    [setDisplayed, domain, setCookie, router]
  );

  if (!displayed) return null;
  if (router.pathname === '/cookie-settings') return null;

  return (
    <div id="cookie-banner">
      <div className="cookie-banner-text">
        Ce site web et ses librairies tiers utilisent des cookies qui sont
        nécessaires à son fonctionnement et à la poursuite de ses objectifs
        décrits dans la{' '}
        <a class="policy-link" href="/p/cookies">
          {' '}
          politique des cookies{' '}
        </a>{' '}
        . Si vous souhaitez en savoir plus ou retirer votre consentement de tout
        ou partie des cookies utilisés, référez-vous à la politique
        d'utilisation des cookies. Fermer cette bannière, scroller, cliquer sur
        un lien ou plus généralement continuer à naviguer sur le site signifie
        implicitement que vous acceptez l'utilisation de cookies.{' '}
      </div>
      <div className="buttons">
        <button
          type="button"
          class="cookie-close"
          onClick={() => setCookies(1, { consent: true })}
        >
          Tout accepter
        </button>{' '}
        <a onClick={() => setCookies(0, { consent: true })}>Tout rejeter</a>{' '}
        <NextLink class="policy-settings" href="/cookie-settings">
          Paramétrer
        </NextLink>{' '}
      </div>
    </div>
  );
}
