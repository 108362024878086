// @flow
import * as React from 'react';

import type { FooterComponent as FooterComponentType } from '@/types/component';

import { COMPONENT_TYPE } from '@/PUFComponents/componentMap';
import ComplexComponent from '@/PUFComponents/ComplexComponent';
import PUFComponent from '@/PUFComponents';

type Options = any;
type Props = {|
  configuredComponent?: FooterComponentType,
  options: Options,
|};

export default function Footer({
  configuredComponent,
  options,
}: Props): React.Node {
  if (configuredComponent) {
    return (
      <footer className="PUFFooter" role="contentinfo">
        <div className="content-container">
          <ComplexComponent component={configuredComponent} />
        </div>
      </footer>
    );
  } else {
    return (
      <PUFComponent type={COMPONENT_TYPE.RAW} id="Footer" options={options} />
    );
  }
}
