import * as React from 'react';

import Register from './NewRegister';
import Login from './NewLogin';

export default function LoginRegister({
  initialChoice,
  canChoose,
  prefill: initialPrefill,
  onSuccess,
  canSubscribe,
  onChoose,
  context,
  options,
}) {
  const [choice, setChoiceState] = React.useState(initialChoice);
  const [prefill, setPrefill] = React.useState(initialPrefill || {});

  const setChoice = React.useCallback(
    (newChoice) => {
      setChoiceState(newChoice);
      onChoose(newChoice);
    },
    [setChoiceState, onChoose]
  );

  switch (choice) {
    case 'login':
      return (
        <Login
          onRegister={
            canChoose
              ? ({ prefill } = {}) => {
                  setPrefill(prefill || {});
                  setChoice('register');
                }
              : undefined
          }
          prefill={prefill}
          onSuccess={onSuccess ? () => onSuccess('login') : undefined}
          canSubscribe={canSubscribe}
          context={context}
        />
      );
    case 'register':
      return (
        <Register
          onLogin={
            canChoose
              ? ({ prefill } = {}) => {
                  setPrefill(prefill || {});
                  setChoice('login');
                }
              : undefined
          }
          prefill={prefill}
          onSuccess={onSuccess ? () => onSuccess('register') : undefined}
          context={context}
          options={options}
        />
      );
    default:
      return (
        <Login
          onRegister={
            canChoose
              ? ({ prefill } = {}) => {
                  setPrefill(prefill || {});
                  setChoice('register');
                }
              : undefined
          }
          prefill={prefill}
          onSuccess={onSuccess ? () => onSuccess('login') : undefined}
          canSubscribe={canSubscribe}
          context={context}
        />
      );
  }
}
