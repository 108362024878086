// @flow
export const CONSENT_SET_COOKIE_NAME = 'cookie_consent_set';
export const CONSENT_PERFORMANCE_COOKIE_NAME = 'cookie_consent_performance';
export const CONSENT_TARGETING_COOKIE_NAME = 'cookie_consent_targeting';
export const CONSENT_SOCIAL_COOKIE_NAME = 'cookie_consent_social';

export type ConsentCookie = {|
  name: string,
  default: 1 | 0,
|};

export const CONSENT_PERFORMANCE_COOKIE: ConsentCookie = {
  name: CONSENT_PERFORMANCE_COOKIE_NAME,
  default: 1,
};

export const CONSENT_TARGETING_COOKIE: ConsentCookie = {
  name: CONSENT_TARGETING_COOKIE_NAME,
  default: 0,
};

export const CONSENT_SOCIAL_COOKIE: ConsentCookie = {
  name: CONSENT_SOCIAL_COOKIE_NAME,
  default: 0,
};

export const CONSENT_COOKIES = [
  CONSENT_PERFORMANCE_COOKIE,
  CONSENT_TARGETING_COOKIE,
  CONSENT_SOCIAL_COOKIE,
];

// Use https://cookiepedia.co.uk/ to where a cookie comes from
export const PERFORMANCE_COOKIE_REGEXP: RegExp = /^(_ga|_gid)/;
export const TARGETING_COOKIE_REGEXP: RegExp =
  /^(_gcl_au|SSID|SAPISID|APISID|OGP|OGPC|HSID|__Secure-3PSID|__Secure-1PSID|__Secure-1PSIDCC|__Secure-1PSID|__Secure-3PSID|__Secure-1PAPISID|__Secure-3PAPISID|__Secure-3PAPISID|__Secure-1PAPISID|__Secure-3PSIDCC)/;

export const BY_PASS_MAINTENANCE_SECRET_COOKIE = 'bypassMaintenanceSecret';
