import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { fromPairs, pick } from 'lodash';

import { getUserFormConfig } from '@/constants/form';
import { useGlobal, useLinks } from '@/hooks';
import { isValidEmail } from '@/utils/misc';
import API from '@/utils/API';
import { UserActions } from '@/reflux';

import PageLink from '@/PUFComponents/base/navigation/PageLinkV1';
import { Flex, Button } from '@/components/new';
import UserFormField from '@/PUFComponents/base/components/UserFormField';
import ForgotPasswordLink from '@/PUFComponents/base/links/ForgotPasswordLink';

export default function NewLogin({
  mainClassName,
  prefill = {},
  onRegister,
  onSuccess,
  canSubscribe,
}) {
  const { subscribe: subscribeLink } = useLinks();
  const { site, siteConfig } = useGlobal();
  const FORM_CONFIG = getUserFormConfig({ site, siteConfig });
  const [loginDoesNotExist, setLoginDoesNotExist] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  const displayedFieldIds = ['login', 'pass'];
  const [fields, setFields] = React.useState(
    fromPairs(
      displayedFieldIds.map((field) => [
        field,
        prefill[field] || FORM_CONFIG.fields[field].default,
      ])
    )
  );
  const [revealPassword, setRevealPassword] = React.useState(false);

  const canLogin =
    !submitting &&
    !loginDoesNotExist &&
    isValidEmail(fields.login) &&
    fields.pass;

  useDebouncedEffect(
    () => {
      const checkIfLoginExists = async () => {
        if (isValidEmail(fields.login)) {
          const exists = await API.get(
            `${site._id}/users/loginExists`,
            { login: fields.login },
            'check-login'
          );

          setLoginDoesNotExist(!exists);
        }
      };

      if (isValidEmail(fields.login)) {
        checkIfLoginExists();
      }
    },
    1000,
    [fields.login]
  );

  return (
    <form className="homemade-form new-login">
      {displayedFieldIds.map((fieldId) => {
        const fieldConfig = FORM_CONFIG.fields[fieldId];

        const options = {};
        let hint;

        if ('pass' === fieldId) {
          options.revealPassword = revealPassword;
          options.toggleViewPassword = () => setRevealPassword(!revealPassword);
          options.type = revealPassword ? 'text' : undefined;
          hint = <ForgotPasswordLink />;
        }

        if (fieldId === 'login' && loginDoesNotExist) {
          hint = (
            <p>
              Nous n'avons pas trouvé de compte correspondant à cet email.{' '}
              {onRegister && (
                <span>
                  Voulez-vous{' '}
                  <a
                    onClick={() =>
                      onRegister({ prefill: pick(fields, displayedFieldIds) })
                    }
                    className="create-account"
                  >
                    créer un compte
                  </a>{' '}
                  ?
                </span>
              )}
              {canSubscribe && (
                <span>
                  Voulez-vous{' '}
                  <PageLink link={subscribeLink}>
                    <a className="create-account">
                      <span>vous abonner</span>
                    </a>
                  </PageLink>
                  ?
                </span>
              )}
            </p>
          );
        }

        return (
          <UserFormField
            key={fieldId}
            fieldConfig={fieldConfig}
            fieldId={fieldId}
            value={fields[fieldId]}
            onChange={(newValue) =>
              setFields((previousFields) => ({
                ...previousFields,
                [fieldId]: newValue,
              }))
            }
            options={options}
            hint={hint}
          />
        );
      })}

      {error && <p className="error">{error}</p>}

      <Flex style={{ justifyContent: 'end' }}>
        {canSubscribe && (
          <PageLink link={subscribeLink}>
            <Button
              additionalClassName="subscribe"
              isText
              style={{ marginRight: 10 }}
            >
              <span>S'abonner</span>
            </Button>
          </PageLink>
        )}

        {onRegister && (
          <Button
            onClick={() =>
              onRegister({ prefill: pick(fields, displayedFieldIds) })
            }
            isOutlined
            style={{ marginRight: 10 }}
          >
            Créer un compte
          </Button>
        )}

        <Button
          disabled={!canLogin}
          onClick={() => {
            if (!canLogin) return;

            setSubmitting(true);
            UserActions.login(
              {
                login: fields.login,
                password: fields.pass,
              },
              (err) => {
                setSubmitting(false);

                if (err) {
                  setError(err.message);
                  return;
                }

                onSuccess && onSuccess();
              }
            );
          }}
        >
          {submitting ? 'Connexion en cours...' : 'Se connecter'}
        </Button>
      </Flex>
    </form>
  );
}
