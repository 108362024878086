// @flow
import _ from 'lodash';
import { withRouter } from 'next/router';
import * as React from 'react';
import nookies from 'nookies';
import Reflux from 'reflux';

import type { Router } from '@/types/router';
import type { User } from '@/types/models';
import type { InstanceStoreProps } from '@/types/stores';

import { isDev } from '@/helpers/env';
import { InstanceActions, CartStoreActions } from '@/reflux';
import { withStore } from '@/utils/HOC';
import config from '@/utils/config';

import { Button } from '@/components/new';

type Props = {||};

type AfterConnectProps = {|
  ...Props,
  router: Router,
  user: User,
  ...InstanceStoreProps,
|};

const shouldToggleFooter = (e: SyntheticKeyboardEvent<*>): boolean => {
  if (e.ctrlKey && e.altKey) {
    if (e.key === 'i' || e.key === 'I') {
      return true;
    }
  } else if (e.shiftKey && e.altKey) {
    if (e.key === 'i' || e.key === 'I') {
      return true;
    }
  } else if (e.shiftKey) {
    if (e.key === 'i' || e.key === 'I') {
      return true;
    }
  }

  return false;
};

function AdminFooter({
  user,
  router,
  instanceName,
  instance,
  instanceList,
  allowSwitchInstance,
  draftMode,
}: AfterConnectProps): React.Node {
  const [displayed, setDisplayed] = React.useState(false);

  const changeInstance = (newInstanceName) => {
    if (instanceName === newInstanceName) return;

    nookies.set(null, 'instanceName', newInstanceName, { path: '/' });
    CartStoreActions.empty();
  };

  // Catch appropriate key and toggle the footer
  React.useEffect(() => {
    function onKeyDown(e) {
      if (shouldToggleFooter(e)) {
        setDisplayed(!displayed);
        e.preventDefault();
        e.cancelBubble = true;
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      }
    }
    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [displayed, setDisplayed]);

  // React.useEffect(() => {
  //   if (!!document.documentElement) {
  //     // TODO didmount + calc height
  //     document.documentElement.style.setProperty(
  //       '--bottom-margin-admin-footer',
  //       '3em'
  //     );
  //   }
  // }, []);

  if (typeof window === 'undefined' || _.isEmpty(instanceList)) {
    return null;
  }

  if (!displayed) return null;

  return (
    <div className="AdminFooter">
      <div className="siteWrapper">
        <div className="AdminFooter-wrapper">
          <span>Base de donnée : </span>

          {allowSwitchInstance && !!instanceList && instanceList.length > 1 ? (
            <select
              value={instanceName}
              onChange={(e) => {
                changeInstance(e.target.value);
                window.location.reload();
              }}
              id="instance-select"
            >
              {_.sortBy(instanceList, 'label').map((instance) => (
                <option
                  value={instance.id}
                  key={instance.id}
                  id={`${instance.id}-option`}
                >
                  {instance.label}
                </option>
              ))}
            </select>
          ) : (
            <span>{instanceName}</span>
          )}

          <input
            id="draft"
            type="checkbox"
            checked={draftMode}
            onChange={(e) => InstanceActions.setDraft(e.target.checked)}
          />

          <label htmlFor="draft">&nbsp;Edition temps réel</label>

          {user ? (
            <span style={{ color: 'green' }}>
              👤{user.login}
              {user.isTester && <span title="utilisateur de test"> (T)</span>}
            </span>
          ) : (
            <span style={{ color: 'red' }}>💀(déconnecté)</span>
          )}

          {process.env.NODE_ENV !== 'production' && (
            <span>{`[${process.env.NODE_ENV || ''}/${config.PUF_ENV}]`}</span>
          )}

          <span>
            {' '}
            Afficher/cacher:{' '}
            <i>
              <b>Ctrl+Alt+i</b>
            </i>
          </span>

          {isDev() && (
            <Button
              onClick={() => console.log(Reflux.getGlobalState())}
              size="small"
            >
              Log reflux store
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default (withStore(
  ['UserStore', 'InstanceStore'],
  withRouter(AdminFooter)
): React.ComponentType<Props>);
