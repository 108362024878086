import React from 'react';
import cn from 'classnames';
import { useUserAgent } from 'next-useragent';

import { UserAgentContext } from '@/globals/contexts';

import Layout from '@/Layout';
import MobileHeader from './HeaderV2MobileHeader';
import MobileSideMenu from './HeaderV2MobileSideMenu';

const RETRACTED_HEADER_CLASSNAME = 'header-retracted';
const EXPANDED_HEADER_CLASSNAME = 'header-not-retracted';
const HEADER_MOBILE_CLASSNAME = 'header-mobile';

const getClassNameIdentifiedStructure = (structure, className) =>
  structure.filter((row) => !!row.options?.className?.includes(className));

const getExpandedHeaderStructure = (structure) => {
  const classNameIdentifiedRows = getClassNameIdentifiedStructure(
    structure,
    EXPANDED_HEADER_CLASSNAME
  );
  return classNameIdentifiedRows.length > 0
    ? classNameIdentifiedRows
    : structure.slice(0, -2); // tout sauf les 2 derniers
};

const getRetractedHeaderStructure = (structure) => {
  const classNameIdentifiedRows = getClassNameIdentifiedStructure(
    structure,
    RETRACTED_HEADER_CLASSNAME
  );
  return classNameIdentifiedRows.length > 0
    ? classNameIdentifiedRows
    : structure.slice(-2, -1); // avant-denier
};

const getMobileHeaderStructure = (structure) => {
  const classNameIdentifiedRows = getClassNameIdentifiedStructure(
    structure,
    HEADER_MOBILE_CLASSNAME
  );
  return classNameIdentifiedRows.length > 0
    ? classNameIdentifiedRows
    : structure.slice(-1); // dernier
};

export default function HeaderComplexComponent({
  component,
  retracted: retractedProp,
  className,
  toggleMobileMenu,
  mobileMenuOpen,
}) {
  const useragent = React.useContext(UserAgentContext);
  const ua = useUserAgent(useragent);
  const mobileMode = ua.isMobile || ua.isTablet;

  let componentClass = component._id || '';
  if (component && component.className) {
    componentClass = component.className;
  } else if (component && component.objectType && component.mode) {
    componentClass =
      component.objectType +
      component.mode.charAt(0).toUpperCase() +
      component.mode.slice(1);
  }

  const expandedHeaderStructure = getExpandedHeaderStructure(
    component.layout.structure
  );
  const retractedHeaderStructure = getRetractedHeaderStructure(
    component.layout.structure
  );
  const mobileHeaderStructure = getMobileHeaderStructure(
    component.layout.structure
  );

  const retracted = retractedProp && !mobileMenuOpen;
  const structureToDisplay = mobileMode
    ? mobileHeaderStructure
    : retracted
    ? retractedHeaderStructure
    : expandedHeaderStructure;

  const layout = {
    ...component.layout,
    structure: structureToDisplay,
  };

  return (
    <>
      <header
        role="banner"
        className={cn(componentClass, className, {
          'Header-not-retracted': !retracted && !mobileMode,
          'Header--retracted': retracted && !mobileMode,
          'Header--toggled': mobileMenuOpen,
          'Header--mobileMode': mobileMode,
        })}
        data-cc={component._id}
      >
        {mobileMode && <MobileHeader openMenu={() => toggleMobileMenu(true)} />}

        {mobileMode ? (
          <MobileSideMenu
            layout={layout}
            closeMenu={() => toggleMobileMenu(false)}
          />
        ) : (
          <Layout layout={layout} isPageWrapper />
        )}
      </header>
    </>
  );
}
