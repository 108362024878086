// @flow
import * as React from 'react';
import Reflux from 'reflux';

import type { SiteContext as SiteContextType } from '@/types/site';
import type { User, VisitorSession } from '@/types/models';
import type { Router } from '@/types/router';
import type { BindObject } from '@/helpers/data';

import {
  SiteContext,
  LayoutSizeDivisionContext,
  NamespacesContext,
} from '@/globals/contexts';
import { UserStore, UserActions, CartStore, InstanceStore } from '@/reflux';
import { withDataBinding } from '@/utils/HOC';
import LinksContext, { getLinks } from '../contexts/LinksContext';
import { getFeatureFlags } from '@/helpers/models/instance';

import Head from './Head';
import AdminFooter from './AppLayout/AdminFooter';
import AppLayout from './AppLayout';
import CookieContextHandler from './CookieContextHandler';
import Tracker from './Tracker';
import NotificationCenter from './NotificationCenter';
import PaywallManager from '../PaywallManager';

type Props = {|
  Component: React.ComponentType<*>,
  siteContext: SiteContextType,
  pageProps: any,
  baseUrl: string,
  fullUrl: string,
  router: Router,
  user: ?User,
  session: VisitorSession,
  cookies: { [cookieName: string]: string },
  apiContext: any,
|};

type AfterConnectProps = {|
  ...Props,
  bindObject: BindObject,
|};

type State = {|
  user: ?User,
|};

class AppComponent extends React.Component<AfterConnectProps, State> {
  constructor(props) {
    super(props);

    UserActions.set(props.user);
    UserActions.setSession(props.session);
    Reflux.initStore(UserStore);
    Reflux.setGlobalState({
      [InstanceStore.id]: { instanceId: props.siteContext.instanceId },
      [UserStore.id]: { user: props.user, session: props.session },
      [CartStore.id]: {
        instanceId: props.siteContext.instanceId,
        featureFlags: getFeatureFlags(props.siteContext.site),
        site: props.siteContext,
      },
    });
    this.state = {
      user: props.user,
    };
  }

  componentDidMount: any = () => {
    const { bindObject } = this.props;

    bindObject('users', 'session', null, (user: ?User) => {
      UserActions.set(user);
      this.setState({ user });
    });
  };

  render(): React.Node {
    const {
      Component,
      pageProps,
      siteContext,
      router,
      cookies,
      baseUrl,
      fullUrl,
      apiContext,
    } = this.props;
    const { user } = this.state;
    const { siteConfig, instanceId, site } = siteContext;

    if (!siteConfig) return <h1>NO SITE CONFIG</h1>;

    const { options: siteOptions } = siteConfig;

    if (!siteOptions)
      return (
        <div className="site">
          <h1>Site non configuré</h1>
          <h2>
            Renseigner la section <i>Site/Options</i> puis rafraîchir la page
          </h2>
          <AdminFooter />
        </div>
      );

    const links = getLinks(siteContext, fullUrl);

    return (
      <SiteContext.Provider
        value={{
          ...siteContext,
        }}
      >
        <CookieContextHandler cookies={cookies}>
          <LayoutSizeDivisionContext.Provider value={1}>
            <LinksContext.Provider value={links}>
              <NamespacesContext.Provider value={siteContext.namespaces}>
                <>
                  {/* $FlowIgnore */}
                  <Tracker user={user} options={siteOptions} />
                  <Head
                    site={site}
                    siteOptions={siteOptions}
                    styles={siteConfig.styles}
                    baseUrl={baseUrl}
                  />

                  <AppLayout
                    instanceName={instanceId}
                    site={siteContext}
                    page={pageProps}
                    router={router}
                  >
                    <NotificationCenter />

                    <PaywallManager>
                      {(incrementView) => (
                        <Component
                          {...pageProps}
                          baseUrl={baseUrl}
                          fullUrl={fullUrl}
                          user={user}
                          formulas={siteContext.formulas} // temp // TODO use context
                          siteContext={siteContext}
                          router={router}
                          incrementPaywallView={incrementView}
                          apiContext={apiContext}
                        />
                      )}
                    </PaywallManager>
                  </AppLayout>
                </>
              </NamespacesContext.Provider>
            </LinksContext.Provider>
          </LayoutSizeDivisionContext.Provider>
        </CookieContextHandler>
      </SiteContext.Provider>
    );
  }
}

export default (withDataBinding<Props>(
  AppComponent
): React.ComponentType<Props>);
