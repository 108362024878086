import { get } from 'lodash';

export const getUserFormConfig = (context) => {
  const { siteConfig } = context;

  return {
    fields: {
      login: {
        label: 'email',
        displayLabel: false,
        placeholder: 'Email*',
        type: 'email',
        name: 'email',
        valueType: 'string',
        autoComplete: 'email',
        required: true,
        default: '',
      },
      pass: {
        label: 'mot de passe',
        displayLabel: false,
        placeholder: 'Mot de passe*',
        type: 'password',
        name: 'password',
        valueType: 'string',
        autoComplete: 'password',
        required: true,
        default: '',
      },
      confirmPass: {
        label: 'confirmation de mot de passe',
        displayLabel: false,
        placeholder: 'Confirmer le mot de passe*',
        type: 'password',
        name: 'password',
        valueType: 'string',
        autoComplete: 'password',
        required: true,
        default: '',
      },
      title: {
        label: 'civilité',
        displayLabel: false,
        placeholder: 'Civilité',
        name: 'title',
        options: [
          { label: 'M.', value: 'M.' },
          { label: 'Mme', value: 'Mme' },
        ],
        valueType: 'enum',
        autoComplete: 'title',
        default: null,
        required: true,
      },
      firstName: {
        label: 'prénom',
        displayLabel: false,
        placeholder: 'Prénom*',
        valueType: 'string',
        autoComplete: 'given-name',
        required: true,
        default: '',
      },
      lastName: {
        label: 'nom',
        displayLabel: false,
        placeholder: 'Nom*',
        valueType: 'string',
        autoComplete: 'family-name',
        required: true,
        default: '',
      },
      nickname: {
        label: 'pseudo',
        displayLabel: false,
        default: '',
        placeholder: 'Pseudonyme',
        valueType: 'string',
        autoComplete: 'nickname',
      },
      birthDateTs: {
        label: 'date de naissance',
        displayLabel: false,
        default: null,
        placeholder: 'jj/mm/aaaa',
        valueType: 'date',
        autoComplete: 'birthDate',
        type: 'date',
      },
      tel: {
        label: 'téléphone',
        displayLabel: false,
        default: null,
        placeholder: 'Téléphone',
        valueType: 'string',
        autoComplete: 'tel',
      },
      receiveNewsletter: {
        default: true,
        label:
          'Je souhaite m’abonner à la Newsletter de ' +
          get(siteConfig, 'options.siteTitle'),
        valueType: 'bool',
      },
      receiveSpam: {
        default: true,
        label:
          'J’accepte de recevoir les communications de ' +
          get(siteConfig, 'options.siteTitle'),
        valueType: 'bool',
      },
    },
  };
};
