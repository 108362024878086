// @flow

import nookies, { destroyCookie } from 'nookies';
import { uniq } from 'lodash';

import type { CookieOptions } from '@/types/cookies';

import {
  PERFORMANCE_COOKIE_REGEXP,
  TARGETING_COOKIE_REGEXP,
} from '@/constants/cookies';

export const clearTrackingCookies = ({
  performanceCookieValue,
  targetingCookieValue,
  socialCookieValue,
}: {|
  performanceCookieValue: number,
  targetingCookieValue: number,
  socialCookieValue: number,
|}) => {
  const cookies = nookies.get({});
  let toRemoveCookies = [];

  if (performanceCookieValue !== 1) {
    toRemoveCookies = [
      ...toRemoveCookies,
      ...Object.keys(cookies).filter((cookieName) =>
        cookieName.match(PERFORMANCE_COOKIE_REGEXP)
      ),
    ];
  }

  if (targetingCookieValue !== 1) {
    toRemoveCookies = [
      ...toRemoveCookies,
      ...Object.keys(cookies).filter((cookieName) =>
        cookieName.match(TARGETING_COOKIE_REGEXP)
      ),
    ];
  }

  uniq(toRemoveCookies).forEach((cookieName) => {
    destroyCookie(null, cookieName);
  });
};

type SetConsentCookie = (
  setCookie: (
    ctx: any,
    cookieName: string,
    cookieValue: string,
    CookieOptions
  ) => void,
  name: string,
  value: number | string,
  domain: string
) => void;

export const setConsentCookie: SetConsentCookie = (
  setCookie,
  name,
  value,
  domain
) => {
  setCookie(null, name, value?.toString(), {
    domain,
    path: '/',
    maxAge: 60 * 60 * 24 * 365,
  });
};
