// @flow

import type {
  SiteContext as SiteContextType,
  URLInformation as URLInformationType,
} from '@/types/site';
import type { NextContext, Request } from '@/types/next';

import { getFeatureFlags } from './models/instance';

export const retrieveInstanceInformation = async (
  context: NextContext,
  api: any
): Promise<SiteContextType> => {
  const siteId = context.req.siteId;

  const siteInitialization = context.req.siteInitialization;
  const headerComponent = siteInitialization.complexComponents.find(
    (complexComponent) => complexComponent._id === 'header'
  );
  const footerComponent = siteInitialization.complexComponents.find(
    (complexComponent) => complexComponent._id === 'footer'
  );
  const featureFlags = getFeatureFlags(siteInitialization.site);

  return {
    ...siteInitialization,
    instance: siteInitialization?.site,
    instanceName: siteId,
    instanceId: siteId,
    siteId,
    headerComponent,
    footerComponent,
    featureFlags,
  };
};

export const getURLInformation = (
  request: Request,
  query: Object
): URLInformationType => {
  const host = request.headers.host;
  const protocol = request.headers['x-forwarded-proto']
    ? request.headers['x-forwarded-proto'].split(/\s*,\s*/)[0]
    : request.connection.encrypted
    ? 'https'
    : 'http';
  const baseUrl = `${protocol}://${host}`;

  return {
    host: request.headers.host,
    pathname: request.url,
    baseUrl,
    fullUrl: baseUrl + request.url,
    query,
  };
};
