import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { fromPairs, intersection, flatten, pick } from 'lodash';

import { getUserFormConfig } from '@/constants/form';
import { useGlobal } from '@/hooks';
import { isValidEmail } from '@/utils/misc';
import API from '@/utils/API';
import { UserActions } from '@/reflux';
import { gtmEventRegister, setGtmUserStatus } from '@/utils/googleTagManager';
import { capitalizeFirstLetter } from '@/helpers/string';

import UserFormField from '@/PUFComponents/base/components/UserFormField';
import { Flex, Button } from '@/components/new';

export default function NewRegister({
  mainClassName,
  options = {},
  prefill = {},
  onLogin,
  onSuccess,
  context, // registration context,
}) {
  const { site, siteConfig } = useGlobal();
  const FORM_CONFIG = React.useMemo(
    () => getUserFormConfig({ site, siteConfig }),
    [site, siteConfig]
  );
  const [loginExists, setLoginExists] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const hasFormError = Object.keys(formErrors).some(
    (fieldId) => !!formErrors[fieldId]
  );

  const displayedFieldIds = React.useMemo(
    () =>
      options.fields || [
        'login',
        'pass',
        'confirmPass',
        'title',
        'firstName',
        'lastName',
        'receiveNewsletter',
        'receiveSpam',
      ],
    [options.fields]
  );
  const [fields, setFields] = React.useState(
    fromPairs(
      displayedFieldIds.map((field) => [
        field,
        prefill[field] || FORM_CONFIG.fields[field].default,
      ])
    )
  );
  const [revealPassword, setRevealPassword] = React.useState(false);
  const canRegister = !submitting && !loginExists && !hasFormError;

  const onFieldChange = React.useCallback(
    (id, value) => {
      setFields((previousFields) => ({ ...previousFields, [id]: value }));
      setFormErrors((previousErrors) => ({ ...previousErrors, [id]: null }));
    },
    [setFields, setFormErrors]
  );

  const validateForm = React.useCallback(() => {
    const newFormErrors = {};

    if (!isValidEmail(fields.login))
      newFormErrors.login = "L'adresse mail n'est pas valide";
    if (fields.confirmPass !== fields.pass)
      newFormErrors.confirmPass =
        'Vous avez saisi des mots de passes différents';

    displayedFieldIds.forEach((fieldId) => {
      const fieldConfig = FORM_CONFIG.fields[fieldId];
      if (!fieldConfig.required || !!fields[fieldId]) return;
      newFormErrors[fieldId] = `${capitalizeFirstLetter(
        fieldConfig.label
      )} est requis`;
    });

    setFormErrors(newFormErrors);
    const hasError = Object.keys(newFormErrors).some(
      (fieldId) => !!newFormErrors[fieldId]
    );
    return !hasError;
  }, [FORM_CONFIG, fields, displayedFieldIds]);

  useDebouncedEffect(
    () => {
      const checkIfLoginExists = async () => {
        if (isValidEmail(fields.login)) {
          const exists = await API.get(
            `${site._id}/users/loginExists`,
            { login: fields.login },
            'check-login'
          );

          setLoginExists(exists);
        }
      };

      if (isValidEmail(fields.login)) {
        checkIfLoginExists();
      }
    },
    1000,
    [fields.login]
  );

  const mandatoryFields = intersection(
    ['login', 'pass', 'confirmPass'],
    displayedFieldIds
  );
  const nameFields = intersection(
    ['title', 'firstName', 'lastName'],
    displayedFieldIds
  );
  const otherFields = intersection(
    ['nickname', 'birthDateTs', 'tel', 'receiveNewsletter', 'receiveSpam'],
    displayedFieldIds
  );

  return (
    <form className="homemade-form new-register">
      {flatten(
        [mandatoryFields, nameFields, otherFields].map((fieldIds) =>
          fieldIds.map((fieldId) => {
            const fieldConfig = FORM_CONFIG.fields[fieldId];

            const options = {};
            let hint;

            if (['pass', 'confirmPass'].includes(fieldId)) {
              options.revealPassword = revealPassword;
              options.toggleViewPassword = () =>
                setRevealPassword(!revealPassword);
              options.type = revealPassword ? 'text' : undefined;
            }

            if (fieldId === 'login' && loginExists) {
              hint = (
                <p>
                  Cet email est déjà utilisé.
                  {onLogin && (
                    <span>
                      {' '}
                      Voulez-vous vous{' '}
                      <a
                        onClick={() =>
                          onLogin({ prefill: pick(fields, mandatoryFields) })
                        }
                        className="login"
                      >
                        connecter
                      </a>{' '}
                      ?
                    </span>
                  )}
                </p>
              );
            }

            return (
              <UserFormField
                key={fieldId}
                fieldConfig={fieldConfig}
                fieldId={fieldId}
                value={fields[fieldId]}
                onChange={(newValue) => onFieldChange(fieldId, newValue)}
                options={options}
                hint={hint}
                error={formErrors[fieldId]}
              />
            );
          })
        )
      )}

      {error && <div className="error">{error}</div>}
      <Flex style={{ justifyContent: 'end', marginTop: 10 }}>
        {onLogin && (
          <Button
            onClick={() => onLogin({ prefill: pick(fields, mandatoryFields) })}
            isOutlined
            style={{ marginRight: 10 }}
          >
            Se connecter
          </Button>
        )}

        <Button
          disabled={!canRegister}
          onClick={() => {
            if (!canRegister) return;
            if (!validateForm()) return;

            setSubmitting(true);
            UserActions.register(
              {
                ...fields,
              },
              context,
              (err, user) => {
                setSubmitting(false);

                if (err) {
                  setError(err.message?.message || err.message);
                  return;
                }

                user && gtmEventRegister();
                setGtmUserStatus(user);
                onSuccess && onSuccess(user, 'register');
              }
            );
          }}
        >
          {submitting ? 'Inscription en cours...' : "S'inscrire"}
        </Button>
      </Flex>
    </form>
  );
}
