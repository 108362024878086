import nookies from 'nookies';

/**
 * Record affiliate from "affiliate" GET parameter, if not already recorded and rightOwnerId != null.
 *
 * @param ctx server/client context (@see getInitialProps)
 */
export function trackAffiliateFromQuery(ctx) {
  if (ctx.query && ctx.query.affiliate) {
    // TODO we should validate this ID...
    trackAffiliate(ctx.query.affiliate, ctx, ctx.asPath, 'URL');
  }
}

/**
 * Record affiliate, if not already recorded and rightOwnerId != null.
 *
 * @param rightOwnerId
 * @param ctx server/client context (@see getInitialProps) or null (client only)
 */
export function trackAffiliate(rightOwnerId, ctx, origin, type) {
  if (!ctx && typeof window === 'undefined')
    console.error("Can't set cokkie server-side without context object");

  if (rightOwnerId) {
    if (!nookies.get(ctx || {}).affiliate) {
      console.debug('New affiliate:', rightOwnerId, origin, type);
      nookies.set(
        ctx || {}, // note: the {} does not seem necessary
        'affiliate',
        JSON.stringify({ rightOwnerId, origin, type }),
        { path: '/', maxAge: 30 * 24 * 3600 } // 1 month
      );
    }
  }
}

if (typeof window !== 'undefined') {
  document.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.altKey) {
      if (e.key === 'a' || e.key === 'A') {
        let current =
          nookies.get().affiliate && JSON.parse(nookies.get({}).affiliate);
        if (current)
          alert(
            'Affilié actuel : ' +
              current.rightOwnerId +
              '\norigine : ' +
              current.type +
              ' ' +
              current.origin
          );
        else alert('Aucun affilié actuellement');
      }
      if (e.key === 's' || e.key === 'S') {
        nookies.destroy(null, 'affiliate', { path: '/' });
        alert('Suppression affiliation');
      }
    }
  });
}
