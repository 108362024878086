// @flow
import * as React from 'react';
import { useRouter } from 'next/router';

import type { Site, SiteConfigOptions } from '@/types/site';

import { BY_PASS_MAINTENANCE_SECRET_COOKIE } from '@/constants/cookies';
import { getApiUrl } from '@/utils/config';
import { CookiesContext } from '@/globals/contexts';
import { getCookieDomain } from '@/helpers/url';

import { RichTextContent } from '@/components/old';

type Props = {
  site: Site,
  siteOptions: SiteConfigOptions,
};

export default function MaintenancePage({
  site,
  siteOptions,
}: Props): React.Node {
  const {
    maintenanceModeDescription,
    bypassMaintenanceSecret: expectedBypassMaintenanceSecret,
  } = site;
  const router = useRouter();
  const { setCookie } = React.useContext(CookiesContext);

  React.useEffect(() => {
    const {
      query: { bypassMaintenanceSecret },
    } = router;

    if (bypassMaintenanceSecret === expectedBypassMaintenanceSecret) {
      const domain = getCookieDomain();
      setCookie(
        null,
        BY_PASS_MAINTENANCE_SECRET_COOKIE,
        bypassMaintenanceSecret,
        {
          maxAge: 60 * 60 * 24,
          sameSite: 'Lax',
          secure: true,
          domain,
          path: '/',
        }
      );
      router.replace(router.pathname, router.asPath.split('?')[0]);
    }
  }, [router, expectedBypassMaintenanceSecret, setCookie]);

  return (
    <div className="maintenance-page">
      <img
        className="maintenance-page-image"
        src={getApiUrl() + siteOptions.emailHeaderImage}
        alt="Logo"
      />
      <div className="maintenance-page-content">
        <h1 className="maintenance-page-title">
          <span className="site-title">{siteOptions.siteTitle}</span> <br />
          est en cours de maintenance
        </h1>
        <div className="maintenance-page-subtitle">
          {maintenanceModeDescription ? (
            <RichTextContent html={maintenanceModeDescription} />
          ) : (
            <p>
              Le site sera bientôt de nouveau accessible, revenez dans quelques
              heures.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
